import React from 'react'

import styles from './style.module.scss'
import Button from '../button'

export default () => {
    return (
        <div className="wrapper">
            <div className="row">
                <div className="col-xs-12">
                    <h1 className={styles.pageTitle}>Jobs portal</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6">
                    <div className={styles.block}>
                        <h2 className={styles.title}>For Employer</h2>
                        <p>
                            Your next hire is here. We help plenty of real
                            estate job seekers and employers find the right fit
                            every day. Start hiring now using the friendliest
                            real estate portal for free.
                        </p>
                        <Button link="/post-job">Post job</Button>
                        <Button link="/resumes">Find resume</Button>
                    </div>
                </div>

                <div className="col-xs-6">
                    <div className={styles.block}>
                        <h2 className={styles.title}>For Applicant</h2>
                        <p>
                            Your next job is on the way is here. We help you
                            land the right job to start or continue your career
                            in real estate. Search for the right vacancy or post
                            your C.V. for free to get noticed.
                        </p>
                        <Button link="/careers">Find job</Button>
                        <Button link="/upload-cv">Upload your CV</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
